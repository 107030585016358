import { isObject } from '@coaf-library/helpers/helperFunctions';
import { ComponentOptions, ComponentProperty, NodePage, PopulatedFlow, Value } from '../IO/db';
import { findNextNode } from './graph';

export interface CustomNext {
  nextFlowId: string;
  nextFlow: unknown;
  nodeId: string;
}

export class FlowService {
  constructor(private flow: PopulatedFlow, public appName: string) {}

  flowName = this.flow?.name;

  initialState = this.getFirstNode();

  private currentState: NodePage | undefined = this.initialState;
  readonly getCurrentState = this.currentState;

  // transitionStateFromValues(values: Value[]) {
  //   const nextNode = this.getNextNode(this.currentState?.nodeId, values)
  //   this.transitionState(nextNode?.nodeId)
  //   return true
  // }

  transitionStateFromNode(transitionTo: string): boolean {
    this.transitionState(transitionTo);
    return true;
  }

  private transitionState(transitionTo?: string) {
    if (transitionTo === undefined) {
      return false;
    }

    this.setFromNode(this.currentState?.nodeId, transitionTo);
    const node = this.getNode(transitionTo);
    if (node === undefined) {
      throw new Error(`Node does not exist ${transitionTo}`);
    }
    this.currentState = node;
  }

  popNode(): void {
    const from = this.currentState?.from;
    this.currentState = this.getNode(from);
  }

  /**
   * Used to get a node from the flow
   * A node is used to represent where in the flow a user is.
   */
  getNode(ID: string | undefined): NodePage | undefined {
    const node = this.flow?.nodes.find((x) => x.nodeId.toLowerCase() === ID?.toLowerCase());
    if (node === undefined) {
      // return;
      throw new Error(`Node with ID: ${ID} does not exist in flowId ${this.flow.name}`);
    }
    return node;
  }

  /**
   * Used to get a node from the flow
   * A node is used to represent where in the flow a user is.
   */
  getNodeIndex(ID: string | undefined): number {
    return this.flow?.nodes.findIndex((x) => x.nodeId.toLowerCase() === ID?.toLowerCase());
  }

  /**
   * Used to get the next node from the flow.
   * This is done based on the value given to the flow and the current node ID.
   */
  getNextNode(currentID: string, values: Value[]): undefined | Record<string, unknown> {
    const page = this.getNode(currentID) as NodePage;
    const next = findNextNode(page, values) as CustomNext | null;
    if (next === null) {
      return undefined;
    }
    if (next.nextFlowId) {
      return { hasNextFlow: true, nextFlow: next.nextFlowId };
    }
    const nextNode = this.getNode(next?.nodeId);
    return { hasNextFlow: false, nextNode };
  }

  /**
   * Used to get the previous node from the flow.
   * This is done based on the value given to the flow
   */
  // getPreviousNode(currentID: string): NodePage {
  //   /**
  //    * TODO: Get previous nodes
  //    * After talking with Frank, we agreed that going backwards is a simple `from` string,
  //    * and the backend constructs the graph based on the values received by the user in the database.
  //    */
  //   const page = this.getNode(currentID) as NodePage;
  //   const index = this.getNodeIndex(page.nodeId);
  //   return this.flow.nodes[index - 1];
  //   // const fromNodeID = page.from
  //   // const fromPage = this.getNode(fromNodeID)
  //   // if (fromPage === undefined) {
  //   //   return page
  //   // }
  //   // return fromPage
  // }

  /**
   * Used to get the previous node from the flow.
   * This is done based on the value given to the flow
   */
  getPreviousNode(currentID: string): NodePage | null | undefined {
    const node = this.getNode(currentID);
    const from = node?.from ?? (node?.froms[0] as string);
    if (from === null || from === undefined) {
      return null;
    }
    return this.getNode(from);
  }

  getAllPreviousNodes(currentID: string | undefined): Array<NodePage | undefined> {
    const node = this.getNode(currentID);
    if (node?.from === null) return [node];
    return [node].concat(this.getAllPreviousNodes(node?.from));
  }

  /**
   * Reset the flow
   */
  getFirstNode(): NodePage {
    return this.getNode(this.flow?.startNodeId) as NodePage;
  }

  /**
   * List flow
   */
  listFlow(): PopulatedFlow {
    return this.flow;
  }

  private setFromNode(fromNodeID: string | undefined, toNodeID: string): void {
    const nodeIndex = this.getNodeIndex(toNodeID);
    if (nodeIndex < 0) {
      console.error('No node', toNodeID);
      return;
    }
    this.flow.nodes[nodeIndex].from = fromNodeID;
  }
  /**
   * Set value on node in the client
   */
  setNodeValue(ID: string, values: Value[]): void {
    const nodeIndex = this.getNodeIndex(ID);
    if (nodeIndex < 0) {
      return;
    }
    values.forEach((output) => {
      const node = this.flow.nodes[nodeIndex].components.find((x) => x.componentId == output.componentId);
      if (node === undefined) {
        throw new Error(`Node does not have componentId: ${output.componentId}`);
      }
      node.values = [output];
    });
    this.setNodeValue2(nodeIndex, values);
    // node.values = values;
  }

  setNodeValue2(index: number, values: Value[]): void {
    this.flow.nodes[index].values = values;

    if (values.length) {
      values.forEach((value: Value) => {
        const comp: ComponentProperty | undefined = this.flow.nodes[index].components.find(
          (comp: ComponentProperty) => comp.componentId.toLowerCase() === value.componentId.toLowerCase()
        );
        if (comp?.values) comp.values = values;
      });
    }
  }

  getFlowLength(): number {
    const length = this.listFlow().nodes.length;
    return length;
  }

  getCurrentPosition(): number {
    return this.getNodeIndex(this.currentState?.nodeId);
  }

  //Is matching value field
  private areEqual(a: ComponentProperty, b: Value): boolean {
    if (a.componentId !== b.componentId) {
      return false;
    }
    if (a.fields.some((x) => x.fieldId === b.fieldId) == false) {
      return false;
    }
    return true;
  }

  //Is component optional
  private isComponentOptional(comp: ComponentProperty) {
    if ((comp.options as ComponentOptions)?.optional) return true;
    if (comp.fields?.every((field) => field.optional)) return true;
    return false;
  }

  //Is individual component filled
  private isComponentValid(comp: ComponentProperty, values: Value[]): boolean {
    if (!this.isInputComponent(comp) || this.isComponentOptional(comp)) return true;
    if (!values || values.length < 1) return false;
    const newVal = values?.every((el) => checkValidity(el.value));
    return newVal;
  }

  private isInputComponent(comp: ComponentProperty) {
    const submitBtnEnabledIfOptional = [
      'checkboxImage',
      'checkboxNoImage',
      'radioImage',
      'radioNoImage',
      'slider',
      'file',
    ];
    const submitBtnDisabledIfOptional = ['consent', 'text', 'email', 'address', 'textArea', 'suggestionsInput'];

    const types = submitBtnEnabledIfOptional.concat(submitBtnDisabledIfOptional);
    return types.some((el) => el.toLowerCase() === comp.type?.toLowerCase());
  }

  hasMatch(comp: ComponentProperty, values: Value[]): boolean {
    return values.some((value) => this.areEqual(comp, value));
  }

  areAllInputComponentsValid(comps: ComponentProperty[], values: Value[]): boolean {
    const optionalInputComponents: ComponentProperty[] = [];
    const allRequired: ComponentProperty[] = [];
    let allRequiredAreValid = false;

    //Is there any filled in optional component if there is no required
    comps.forEach((component: ComponentProperty) => {
      if (this.isInputComponent(component)) {
        if (this.isComponentOptional(component)) {
          optionalInputComponents.push(component);
        } else {
          allRequired.push(component);
          // all required have to have a value filled in
          allRequiredAreValid = this.isComponentValid(component, values);
        }
      }
    });

    const thereAreNoInputComponents = optionalInputComponents.length + allRequired.length === 0;
    const atLeastOneOptionalComponentFilledOut = optionalInputComponents.some((component) =>
      this.hasMatch(component, values)
    );

    if (thereAreNoInputComponents) return true;
    if (allRequired.length === 0 && atLeastOneOptionalComponentFilledOut) return true;

    if (allRequired.length > 0 && allRequiredAreValid) return true;

    return false;
  }
}

export const createFlowService = (flow: PopulatedFlow, appName: string): FlowService => new FlowService(flow, appName);

export default createFlowService;

export function checkValidity(val: unknown[]): boolean {
  if (Array.isArray(val)) return val.length > 0;
  if (isObject(val)) return Object.keys(val).length > 0;
  return val !== undefined && val !== null && val !== '';
}
